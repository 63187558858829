// @flow 
import { Button, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import * as React from 'react';

const ListItemComponent = ({text, icon, handleItem, type, name}) => {

    return (
        <a href="#formGenerator" className={type=== name ? "iconLists activeItem": "iconLists"}>
            <ListItem disablePadding>
                <ListItemButton onClick={handleItem}>

                    <div className='iconList'>
                        {icon}
                    </div>
                    <ListItemIcon>
                    </ListItemIcon>
                    <ListItemText primary={text} />
                </ListItemButton>                           
            </ListItem>
            </a>
    );
};

export default ListItemComponent;