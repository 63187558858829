import './App.css';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom';
import Qrcode from './containers/Qrcode';
import { BrowserRouter as Router} from 'react-router-dom';

// import for toast
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <> 
        <Router basename="/#">
           <Switch>
              <Route path={"/"} component={Qrcode}/>
            </Switch>  
        </Router>
        <ToastContainer position={toast.POSITION.BOTTOM_LEFT}/>

  </> 
  );
}

export default App;
