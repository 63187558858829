import { Button, Grid, IconButton, ListItemButton, ListItemText } from '@mui/material';
import QRCode from 'qrcode';
import { useState, useEffect } from 'react';
import logo from '../assets/DivQr.png';
import logoDivhart from "../assets/DIVHART-logo-v3.png"
import TypeList from "./components/TypeList";
import FormComponent from './components/FormComponent';
import { toast } from 'react-toastify';
import QrGenerator from '../services/QrGenerator';
import { withRouter } from 'react-router-dom';
import { createBrowserHistory } from '@remix-run/router';
import logoFavicon from "../assets/logo-favicon.png";
import ShareSMedia from './components/ShareSMedia';
import { ArrowCircleDown } from '@mui/icons-material';

function Qrcode({history}) {
    
        const[type, setType] = useState('');
        const[url, setUrl] = useState('');
        const[sms, setSms] = useState({
            tel: "",
            message: ""
        });
        const[wifi, setWifi] = useState({
            authentification: "",
            nom: "",
            password: "",

        });
        const[qrImage, setQrImage] = useState('');
        const [valueToCode, setValueToCode]= useState('');
        const[error, setError] = useState('');

        useEffect(() => {
            if(valueToCode){
               QRCode.toDataURL(valueToCode, {
                width: 800,
                margin: 2,
                color : {
                    // dark: '#335383FF',
                    // light: '#EEEEEEEFF'
                }
            },(error, URL) => {
                if (error) return console.mistake(error);
                console.log(URL);
                setQrImage(URL);
            } ) 
            }
            

        },[valueToCode]);

        useEffect(() => {
            if(error){
                toast.error(error);
            }
        },[error]);

        const GenerateQRCode = () => {
            let preparedValue= QrGenerator(url, sms, type, wifi);
            if(preparedValue.error){
                setError(preparedValue.error);
            }else{
                setValueToCode(preparedValue.valueToCode);
            }
        }

        const renitialize = ( ) => {
            setUrl(null);
            setSms(null);
            setWifi(null);
            setQrImage(null);
            document.getElementById("inputUrl").value="";
        }

        const handleKeyDown = function (e) {
            if (e.key === 'Enter') {
                GenerateQRCode();
              }
        }

        const setTypeHandled = (typeHandled) => {
            setUrl("");
            setSms("");
            setWifi("");
            setType(typeHandled);
        };

        const handleSetUrl = (url, type, nameOfField) => {
            if( type ==="text" || 
            type ==="url" || 
            type ==="telephone" || 
            type ==="email" || 
            type ==="facebook" || 
            type ==="twitter"){
                setUrl(url);
            }else if(type ==="sms"){
                setSms({...sms, [nameOfField]: url});
            }
            else if(type ==="wifi"){
                setWifi({...wifi, [nameOfField]: url});
            }
        };

        useEffect(() => {
            if(!type){
            setType("text");   
            }
        },[]);

        let deferredPrompt;
        var buttonInstall = document.getElementById('install');


        window.matchMedia('(display-mode: standalone)')
        .addEventListener('change', ({ matches }) => {
            if (matches) {
                console.log("déja installé");
                buttonInstall.hidden();
            } else {
                console.log("non installé");
                buttonInstall.show();
            }
        });

        window.addEventListener('beforeinstallprompt', function (e) {

        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();

        // Stash the event so it can be triggered later.
        deferredPrompt = e;

        });


        if(buttonInstall){
            buttonInstall.addEventListener('click', function (e) {
        // Show the install prompt
        deferredPrompt && deferredPrompt.prompt();
        });
        }


        return (
            <div className="app">
                <section>
                    <img className="logo" src={logo}/>
                    <h1 className='titleH1'> Logiciel générateur de qr code gratuit - DIVQR </h1>
                    <h3 className='titleH3'> <a href="https://divhart.com" className='divhartTag'>by DIVHART</a></h3>
                    <div class="divider">
                    <span class="">
                                </span>
                </div>
                </section>

                <Grid container spacing={0} justifyContent={{xs:"center",md:"end"}}>

                    <Grid item md={4}>
                        <TypeList 
                        handleItem={setTypeHandled}
                        type={type} />
                    </Grid>

                    <Grid item md={4}>
                        <Grid container spacing={0} justifyContent="start" className='scndSection'>

                            <Grid item md={12}>

                                        <FormComponent
                                        url={url}
                                        sms={sms}
                                        setSms={setSms}
                                        wifi={wifi}
                                        handleSetUrl={handleSetUrl}
                                        handleKeyDown={handleKeyDown}
                                        GenerateQRCode={GenerateQRCode}
                                        type={type} />
                            </Grid>

                                <Grid item md={12}>
                                    { qrImage && <div className='qrCodeDiv'>

                                    <Button variant='contained' 
                                    color='success' 
                                    href={qrImage} 
                                    download="qrcode.png" 
                                    className='btnTelechargement'> Télécharger </Button>

                                    <img src={qrImage}/>

                                    <Button variant='contained' 
                                    color='info' 
                                    className='btnRenitialize'
                                    onClick={renitialize}> Rénitialiser </Button>

                                    </div> }
                                </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={4}>
                    <IconButton id="install" title="Install PWA">
                    <ArrowCircleDown/>
                        <p> Installer l'application ! </p>
                    </IconButton>

                    <ShareSMedia/>

                    <img className="logoDivhart" src={logoDivhart}/>

                    <a href="mailto:contact@divhart.com" className='emailContact'>contact@divhart.com</a>

                    
                    </Grid>

                    
                </Grid>

                <section className='footer'>
                    <img className="logoFavicon" src={logoFavicon}/>
                    <h3 className='titleH3'> <a href="https://divhart.com" className='divhartTag'>powered by DIVHART</a></h3>
                    <div class="divider">
                    <span class="">
                                </span>
                </div>
                </section>

                
            </div>
        )
    }

    export default withRouter(Qrcode);