// @flow 
import * as React from 'react';
import {useState, useEffect} from 'react';
import { Button } from '@mui/material';


const FormComponent = ({url, sms, handleSetUrl, handleKeyDown, GenerateQRCode, type, setSms , wifi}) => {

    const[typeForm, setTypeForm] = useState('');
    const[placeholder, setPlaceholder] = useState('');
    const[typeInputStand, setTypeInputStand] = useState('');

    useEffect(() => {
        if(type){
            if( type ==="text" || 
            type ==="url" || 
            type ==="telephone" || 
            type ==="email" || 
            type ==="facebook" || 
            type ==="twitter"){
                setTypeForm("standard");
                // set placeholder switch value type
            switch (type) {
                case "text":
                    setPlaceholder("Saisissez votre text");
                    setTypeInputStand("text");
                    break;
    
                case "url":
                    setPlaceholder("Exemple www.divhart.com");
                    setTypeInputStand("text");
                    
                    break;
    
                case "telephone":
                    setPlaceholder("Exemple 06594875");
                    setTypeInputStand("tel");
                    break;
    
                case "email":
                    setPlaceholder("Exemple contact@divhart.com");
                    setTypeInputStand("email");
                    break;
    
                case "facebook":
                    setPlaceholder("Url ou nom d'utilisateur");
                    setTypeInputStand("text");
                    break;
                
                case "twitter":
                    setPlaceholder("Url ou nom d'utilisateur");
                    setTypeInputStand("text");
                    break;
                
                default:
                    break;
            }
                }else if(type==="sms"){
                setTypeForm("sms");
                }
                else if(type==="wifi"){
                    setTypeForm("wifi");
                    }

        }
    },[type]);


    const setUrl = (e, type, nameOfField) => {
        if( type ==="text" || 
            type ==="url" || 
            type ==="telephone" || 
            type ==="email" || 
            type ==="facebook" || 
            type ==="twitter"){
            handleSetUrl(e, type, null);
            }else{
            handleSetUrl(e, type, nameOfField);
            }
    };

    return (
            <div className='formQrGenerator' id="formGenerator">
                { 
                typeForm && typeForm === "standard"
                ? 
                <input
                type={typeInputStand}
                placeholder = {placeholder}
                value={url}
                onChange = {e=>setUrl(e.target.value, type, e.target.name) } 
                className="inputUrl"
                id="inputUrl"
                onKeyDown={handleKeyDown}/>
                : null

                }

                { 
                    typeForm && typeForm === "sms"
                    ? 
                    <div>

                    <input
                    type="tel"
                    placeholder = "Numéro de téléphone"
                    value={sms ? sms.tel: null}
                    onChange = {e=>setUrl(e.target.value, type, e.target.name) } 
                    className="inputUrl"
                    id="inputUrl"
                    name="tel"
                    />

                    <input
                    type="text"
                    placeholder = "message"
                    value={sms ? sms.message: null}
                    onChange = {e=>setUrl(e.target.value, type, e.target.name) } 
                    className="inputUrl"
                    id="inputUrl"
                    name="message"
                    />

                    </div>
                    
                    : null
                }

                { 
                    typeForm && typeForm === "wifi"
                    ? 
                    <div>

                    <select name="authentification" 
                    id="authentification"
                    onChange = {e=>setUrl(e.target.value, type, "authentification") }
                    className="inputUrl"
                    >
                    <option value="Aucun">Aucun</option>
                    <option value="WEP">WEP</option>
                    <option value="WPA/WPA2">WPA/WPA2</option>
                    </select>


                    <input
                    type="nom"
                    placeholder = "Nom du réseau"
                    value={wifi ? wifi.nom: null}
                    onChange = {e=>setUrl(e.target.value, type, e.target.name) } 
                    className="inputUrl"
                    id="inputUrl"
                    name="nom"
                    />

                    <input
                    type="password"
                    placeholder = "Mot de passe"
                    value={wifi ? wifi.password : null}
                    onChange = {e=>setUrl(e.target.value, type, e.target.name) } 
                    className="inputUrl"
                    id="inputUrl"
                    name="password"
                    />

                    </div>
                    
                    : null
                }
                

                <Button variant = "contained" 
                                onClick={GenerateQRCode}
                                className="btnGeneration"> 
                                Générer 
                                </Button>
            </div>
            )
};

export default FormComponent;