import { Wifi } from "@mui/icons-material";

export default function QrGenerator(url, sms, type, wifi) {
    let valueToCode, error;
    switch (type) {
        case "text":
            valueToCode=url;
            break;

        case "url":
        if(!url.includes("https://")){
            valueToCode="https://"+ url;
        }
            break;

        case "telephone":
        if(!url.includes("tel:")){
            valueToCode="tel:"+ url;
        }
            break;
        
        case "email":
        if(!url.includes("mailto:")){
            if(!url.includes("@")){
                error="Le format de l'adresse e-mail n'est pas valide";
            }else{
                valueToCode="mailto:"+ url;
            }
        }
            break;


        case "facebook":
        if(!url.includes("facebook.com")){
            valueToCode="www.facebook.com/"+ url;

        }else{
            valueToCode=url;
        }
            break;

        case "twitter":
        if(!url.includes("twitter.com")){
            valueToCode="www.twitter.com/"+ url;
        }else{
            valueToCode=url;
        }
            break;

        case "sms":
        if(sms.tel && sms.message && sms.tel!== "" && sms.message!== ""){
            valueToCode="smsto:"+sms.tel+":"+sms.message;
        }else{
            error="Vous devez remplir les champs !";
        }
            break;
        
        case "wifi":
            valueToCode="WIFI:T:"+ wifi.authentification+";S:"+ wifi.nom+ ";P:"+ wifi.password +";;";
            break;

            default:
                valueToCode=url;
            break;

        }

        return {
            valueToCode: valueToCode,
            error: error
        }
}