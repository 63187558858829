// @flow 
import * as React from 'react';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookMessengerIcon, FacebookMessengerShareButton, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';

const ShareSMedia = ({}) => {
    return (
        <div className='socialMediaShare'>

            <h3 className='titleH1'> Partager sur les réseaux sociaux </h3>
            
            <FacebookShareButton
                    url={"https://qrcode.divhart.com/"}
                    hashtag={"#qrcode"}
                    description={"J'ai crée mon Qr Code gratuitement avec DIVQR"}
                    quote="DIVQR">
                    <FacebookIcon size={40} round /> 
            </FacebookShareButton>

            <FacebookMessengerShareButton>
                <FacebookMessengerIcon size={40} round /> 
            </FacebookMessengerShareButton>

            <TwitterShareButton
            title={"J'ai crée mon Qr Code gratuitement avec DIVQR"}
            url={"https://qrcode.divhart.com/"}
            hashtags={["qrcode", "divhart"]}>
                    <TwitterIcon size={40} round />
            </TwitterShareButton>

            <EmailShareButton
            subject="DIVQR - Logiciel générateur de qr code gratuit"
            body="J'ai crée mon Qr Code gratuitement avec DIVQR https://qrcode.divhart.com/">
            <EmailIcon size={40} round />
            </EmailShareButton>

            <a
            href="https://www.linkedin.com/sharing/share-offsite/?url=https://qrcode.divhart.com/"
            >
            <LinkedinIcon size={40} round />
            </a>

            <WhatsappShareButton
            title="DIVQR - Logiciel générateur de qr code gratuit - https://qrcode.divhart.com/">
                <WhatsappIcon size={40} round />
            </WhatsappShareButton>
            
        </div>
    );
};

export default ShareSMedia;