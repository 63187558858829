// @flow 
import * as React from 'react';

// import of materiel ui components
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { ListItemButton} from '@mui/material';

// Component List
import ListItemComponent from './ListItemComponent';

//icons
import TextFormatIcon from '@mui/icons-material/TextFormat';
import LanguageIcon from '@mui/icons-material/Language';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import SmsIcon from '@mui/icons-material/Sms';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import WifiPasswordIcon from '@mui/icons-material/WifiPassword';

const TypeList = ({handleItem, type}) => {

    return (
        <div>
            <ListItemButton component="a">
                    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <nav aria-label="main mailbox folders" className='typeQr'>
                                        <List>

                                        <ListItemComponent 
                                            text="Text"
                                            name="text"
                                            icon= {<TextFormatIcon/>}
                                            handleItem={() => handleItem("text")}
                                            type={type}/>
                                        <ListItemComponent 
                                            text="Url"
                                            name="url"
                                            icon= {<LanguageIcon/>}
                                            handleItem={() => handleItem("url")}
                                            type={type}/>
                                        <ListItemComponent 
                                            text="Numéro de téléphone"
                                            name="telephone"
                                            icon= {<LocalPhoneIcon />}
                                            handleItem={() => handleItem("telephone")}
                                            type={type}/>
                                        <ListItemComponent 
                                            text="E-mail"
                                            name="email"
                                            icon= {<EmailIcon />}
                                            handleItem={() => handleItem("email")}
                                            type={type}/>
                                        <ListItemComponent 
                                            text="Facebook"
                                            name="facebook"
                                            icon= {<FacebookIcon />}
                                            handleItem={() => handleItem("facebook")}
                                            type={type}/>
                                        <ListItemComponent 
                                            text="Twitter"
                                            name="twitter"
                                            icon= {<TwitterIcon />}
                                            handleItem={() => handleItem("twitter")}
                                            type={type}/>
                                        <ListItemComponent 
                                            text="Sms"
                                            name="sms"
                                            icon= {<SmsIcon />}
                                            handleItem={() => handleItem("sms")}
                                            type={type}/>
                                        <ListItemComponent 
                                            text="Wifi"
                                            name="wifi"
                                            icon= {<WifiPasswordIcon />}
                                            handleItem={() => handleItem("wifi")}
                                            type={type}/>

                                    </List>

                        </nav>
                    </Box>
            </ListItemButton>
        </div>
    );
};

export default TypeList;